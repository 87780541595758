import mixins from 'vue-typed-mixins'
import DraftElement from '@/calendesk/sections/section/mixins/DraftElement'
import { mapActions, mapGetters } from 'vuex'
import Dialog from '@/calendesk/models/Dialog'
import { DialogTypes } from '@/components/dialogs/DialogTypes'
import DialogSize from '@/calendesk/models/DialogSize'

export default mixins(DraftElement).extend({
  computed: {
    ...mapGetters({
      dialog: 'dialog/getDialog',
      dialog2: 'dialog/getDialog2'
    })
  },
  methods: {
    ...mapActions({
      openDialog: 'dialog/openDialog',
      closeDialog: 'dialog/closeDialog',
      openDialog2: 'dialog/openDialog2',
      closeDialog2: 'dialog/closeDialog2'
    }),
    openLoginDialog (): void {
      this.openDialog2(new Dialog(true, DialogTypes.LOGIN, this.$trans('login'), DialogSize.MIDDLE, false, 'center'))
    },
    openSignupDialog () {
      this.openDialog2(new Dialog(true, DialogTypes.SIGN_UP, this.$trans('sign_up'), DialogSize.MIDDLE))
    }
  }
})
