









import mixins from 'vue-typed-mixins'
import DialogMixin from '@/components/dialogs/mixins/DialogMixins'
export default mixins(DialogMixin).extend({
  name: 'ForgottenPasswordDialog',
  components: {
    ForgottenPasswordDialogForm: () => import('./ForgottenPasswordDialogForm.vue')
  }
})
